import * as React from "react";
import PageLayout from "../components/PageLayout";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import SpaceHolder from "../components/Space";
import SideText from "../components/SideText";

const Wrapper = styled.div`
  /* border: 1px solid black; */
  max-width: 1600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  text-align: center;
`;

const Row = styled.div`
  /* border: 1px solid black; */
  position: relative;
  top: -70px;
  width: auto;
  padding: 0px 5vw;
  display: flex;
  flex-wrap: wrap;

  ${(props) => props.theme.sizes.mobile} {
    width: 100vw;
    justify-content: center;
  }
  ${(props) => props.theme.sizes.tablet} {
    width: 100vw;
    justify-content: center;
  }
`;

const Column = styled.div`
  /* border: 1px solid orange; */
  padding: 30px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;

  h3 {
    color: ${(props) => props.theme.colors.color3};
    padding-bottom: 10px;
  }

  p {
    padding-bottom: 3px;
    font-size: 0.9em;
  }

  ${(props) => props.theme.sizes.mobile} {
    width: 55vw;
    padding: 30px 10px;
  }
  ${(props) => props.theme.sizes.tablet} {
    width: 55vw;
    padding: 30px 10px;
  }
`;

const DateRow = styled.div`
  width: 150px;
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-between;

  p {
    padding-left: 6px;
  }
`;

const MapsContainer = styled.div`
  position: relative;
  -webkit-filter: grayscale(70%);
  filter: grayscale(70%);
  width: 100vw;
`;

const BottomText = styled.h4`
  /* background-color: orange; */
  width: 80%;
  padding-bottom: 5px;

  ${(props) => props.theme.sizes.mobile} {
    width: 90%;
  }
  ${(props) => props.theme.sizes.tablet} {
    width: 90%;
  }

  a {
    color: ${(props) => props.theme.colors.color3};
    &:focus,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }
  }
`;

const IndexPage = () => {
  return (
    <>
      <PageLayout>
        <Navbar pageAt="contact" />
        <Wrapper>
          <SpaceHolder height={80} />
          <SideText text="contact" />
          <Row>
            <Column>
              <h3>find us</h3>
              <p>408 dupont st</p>
              <p>toronto, on m5r 1v9</p>
              <p style={{ marginTop: '10px' }}>219 ossington ave</p>
              <p>toronto, on m6j 2z8</p>
            </Column>
            <Column>
              <h3>let's connect</h3>
              <p>pe dupont: 416-732-7719</p>
              <p style={{ marginTop: '10px' }}>pe ossington: 416-834-5887</p>
              <p style={{ marginTop: '10px' }}>hello@projectessence.ca</p>
            </Column>
            <Column>
              <h3>our hours</h3>
              <DateRow>
                <p>everyday: 10am - 8pm</p>
              </DateRow>
            </Column>
          </Row>
          <MapsContainer>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
              <div style={{ flex: 1, minWidth: "300px" }}>
              <iframe
                  width="100%"
                  height="500"
                  loading="lazy"
                  allowFullScreen
                  referrerPolicy="no-referrer-when-downgrade"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2885.760230824375!2d-79.4114711!3d43.6739562!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b35f72b70c6f3%3A0x38be2dd51174a6d3!2sProject%20Essence!5e0!3m2!1sen!2sca!4v1733079262839!5m2!1sen!2sca"
                ></iframe>
              </div>
              
              <div style={{ flex: 1, minWidth: "300px" }}>
                <iframe
                  width="100%"
                  height="500"
                  loading="lazy"
                  allowFullScreen
                  referrerPolicy="no-referrer-when-downgrade"
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAHfJwH3uMkXyMB9xjlosnWLVa8UjgXF0M&q=Project+Essence&zoom=15"
                ></iframe>
              </div>
            </div>;
          </MapsContainer>
          <SpaceHolder height={60} />
          <BottomText>
            want to join our team?{" "}
            <a href="mailto:hello@projectessence.ca?subject=join&">
              hello@projectessence.ca
            </a>
          </BottomText>
          <SpaceHolder height={80} />
        </Wrapper>
      </PageLayout>
    </>
  );
};

export default IndexPage;

export const Head = () => <title>Project Essence</title>;
